const API_BASE_URL = "https://api.videosdk.live";
const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;

// const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;

// export const getToken = async () => {
//   if (VIDEOSDK_TOKEN && API_AUTH_URL) {
//     console.error(
//       "Error: Provide only ONE PARAMETER - either Token or Auth API"
//     );
//   } else if (VIDEOSDK_TOKEN) {
//     return VIDEOSDK_TOKEN;
//   } else if (API_AUTH_URL) {
//     const res = await fetch(`${API_AUTH_URL}/get-token`, {
//       method: "GET",
//     });
//     const { token } = await res.json();
//     return token;
//   } else {
//     console.error("Error: ", Error("Please add a token or Auth Server URL"));
//   }
// };

// generate token through appId
export const getToken = async () => {
  const search = window.location.search;

  const urlParams = new URLSearchParams(search);

  const appId = urlParams.get("appId");
  if (appId) {
    const url = `${API_BASE_URL}/v2/projects/auth-token/${appId}`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        permissions: ["allow_join"],
        expiresIn: "7d",
        roles: ["CRAWLER"],
      }),
    };

    const { authToken: token } = await fetch(url, options)
      .then((response) => response.json())
      .catch((error) => console.error("error in auth token", error));

    return token;
  } else if (VIDEOSDK_TOKEN) {
    return VIDEOSDK_TOKEN;
  } else {
    console.log("Error in token generation");
  }
};

export const createMeeting = async ({ token }) => {
  const url = `${API_BASE_URL}/v2/rooms`;
  const options = {
    method: "POST",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const { roomId } = await fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error("error in create room", error));

  // return "dca9-yrag-tdzy";
  return roomId;
};

export const validateMeeting = async ({ roomId, token }) => {
  const url = `${API_BASE_URL}/v2/rooms/validate/${roomId}`;

  const options = {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const result = await fetch(url, options)
    .then((response) => response.json()) //result will have meeting id
    .catch((error) => console.error("error in validate room", error));

  return result ? result.roomId === roomId : false;
};
